import { en } from 'vuetify/lib/locale'
import HomeView from './views/HomeView-en'
import NavBar from './components/NavBar-en'
import Contact from './components/Contact-en'

export default {
    ...en,
    NavBar,
    HomeView,
    Contact
}
