<template>
    <div>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-navigation-drawer
            v-model="drawer"
            temporary dark absolute>
            <template v-slot:prepend>
                <img
                    alt="DrawerClose"
                    src="@/assets/close.svg"
                    width="20"
                    height="20" />
            </template>
            <v-list>
                <v-list-item
                    v-for="item in items"
                    :key="`drawer-${item.id}`"
                    @click.stop="drawerScroll(item.id)">
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import navMixin from '@/mixins/navMixin'

export default {
    name: 'NavDrawer',

    mixins: [navMixin],

    props: {
        items: {
            type: Array,
            default: () => ([])
        }
    },

    data () {
        return {
            drawer: false
        }
    },

    methods: {
        drawerScroll (id) {
            this.drawer = !this.drawer
            this.$nextTick(() => {
                this.scrollTo(id)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer {
    background-image: linear-gradient(to bottom, #8a6bdd, #3a5cdb);

    .v-list-item {
        padding: 20px 40px;

        .v-list-item__title {
            font-size: 20px;
            font-weight: 500;
        }
    }
}

img {
    margin-top: 24px;
    margin-left: 20px;
}
</style>
