import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCompositionAPI from '@vue/composition-api'
import { VueSpinners } from '@saeris/vue-spinners'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueCompositionAPI)
Vue.use(VueSpinners)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
