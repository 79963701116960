import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from '@/lang/en'
import th from '@/lang/th'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: true
    },
    lang: {
        locales: { th, en },
        current: 'en'
    }
})
