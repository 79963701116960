<template>
    <div class="service-card">
        <div class="service-card-label">{{ content.label }}</div>
        <div class="service-card-title">{{ content.title }}</div>
        <div class="service-card-body">
            {{ $vuetify.lang.t(`$vuetify.${content.body}`) }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceCard',

    props: {
        content: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style lang="scss" scoped>
.service-card {
    position: relative;
    padding: 48px;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #1f1f1f;
    width: 600px;
    z-index: 3;

    &-label {
        position: absolute;
        top: 40px;
        left: 48px;
        font-family: Comfortaa;
        font-size: 100px;
        font-weight: bold;
        color: #fff;
        opacity: 0.03;
        line-height: 1.11;
        z-index: 1;
    }

    &-title {
        margin-top: 30px;
        margin-left: 44px;
        margin-bottom: 24px;
        font-family: Comfortaa;
        font-size: 32px;
        font-weight: normal;
        z-index: 2;

        &::first-letter {
            color: #8a6bdd;
            font-weight: bold;
        }
    }

    &-body {
        margin-left: 20px;
        border-left: 3px solid #8a6bdd;
        padding-left: 24px;
        font-size: 20px;
        font-weight: 300;
        z-index: 2;
    }
}

.en-locale .service-card-body {
    font-family: Quicksand !important;
}
.th-locale .service-card-body {
    font-weight: 200;
}

@media only screen and (max-width: 1024px) {
    .service-card {
        width: 88% !important;
        padding: 24px !important;

        &-label {
            left: 16px !important;
        }

        &-title {
            // margin-top: 30px;
            margin-left: 8px !important;
        }

        &-body {
            font-size: 16px !important;
            margin-left: 16px !important;
            margin-bottom: 24px !important;
        }
    }
}
</style>
