import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        loadingBounce: null
    },
    getters: {
    },
    mutations: {
        setLoading (state, payload) {
            if (state.loadingBounce) {
                clearTimeout(state.loadingBounce)
            }

            if (payload) {
                state.loading = payload
            } else {
                state.loadingBounce = setTimeout(() => {
                    state.loading = payload
                }, 200)
            }
        }
    },
    actions: {
    },
    modules: {
    }
})
