<template>
    <footer>
        <div class="left">
            <img
                alt="ZoxaLogo2"
                src="@/assets/zoxa-logo.png"
                width="64"
                height="12" />
            <div>Copyright © 2021 zoxa co., tld.</div>
        </div>
        <div class="right">
            <LangSelector class="lang-selector" />|
            <img
                alt="Facebook"
                src="@/assets/facebook.png"
                @click="openFacebook"
                width="24"
                height="24" />
        </div>
    </footer>
</template>

<script>
import LangSelector from '@/components/LangSelector'

export default {
    naame: 'Footer',

    components: {
        LangSelector
    },

    methods: {
        openFacebook () {
            window.open('https://www.facebook.com/Zoxa-1508264892638485', '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    height: 90px;
    padding: 0px 80px;
    background-image: linear-gradient(to left, #8a6bdd, #3a5cdb);
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        flex-flow: column;
        justify-content: center;

        div {
            margin-top: 18px;
            font-family: Comfortaa;
            font-size: 12px;
        }
    }

    .right {
        display: flex;
        align-items: center;

        .lang-selector {
            margin-top: 5px;
            margin-right: 16px;
        }

        img[alt="Facebook"] {
            margin-left: 16px;
            cursor: pointer;
        }
    }
}
@media only screen and (max-width: 1024px) {
    footer {
        padding: 0px 20px !important;
    }
}
</style>
