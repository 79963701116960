<template>
    <div>
        <v-dialog
            v-model="dialog"
            @click:outside="close"
            width="500">
            <v-card
                :height="height"
                dark>
                <v-card-text class="message">
                    {{ message }}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Modal',

    props: {
        height: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            dialog: false,
            message: null
        }
    },

    methods: {
        show (msg) {
            this.message = msg
            this.dialog = true
        },

        close () {
            this.dialog = false
            this.message = null
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("@/assets/bg3.png");
    background-position: center center;
}

.message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px !important;
    font-size: 18px;
}
</style>
