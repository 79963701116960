<template>
    <div ref="langSelector" class="lang-selector" @click="toggleMenu()">
        <span>{{ currentLang }}</span>

        <img
            src="@/assets/lang.png"
            alt="lang"
            class="lang-btn">
        <v-menu
            class="lang-menu"
            :position-x="x"
            :position-y="y"
            v-model="showMenu"
            light offset-y>
            <v-list>
                <v-list-item
                    v-for="(lang, index) in langs"
                    :key="index"
                    @click="changeLang(index)">
                    <v-list-item-title
                        class="lang-item">
                        <img
                            :src="getImg(index)"
                            :alt="`lang-icon-${index}`">
                        {{ lang }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'LangSelector',

    data () {
        return {
            showMenu: false,
            x: 0,
            y: 0,
            langs: {
                en: 'EN',
                th: 'ไทย'
            }
        }
    },

    computed: {
        currentLang () {
            return this.langs[this.$vuetify.lang.current]
        }
    },

    methods: {
        getImg (label) {
            return require(`@/assets/${label}.png`)
        },

        toggleMenu (e) {
            this.showMenu = false
            const el = this.$refs.langSelector
            this.x = (2 * (el.offsetLeft + el.offsetWidth)) - this.getPageWidth()
            this.y = el.offsetTop + el.offsetHeight + 8
            this.$nextTick(() => {
                this.showMenu = true
            })
        },

        getPageWidth () {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            )
        },

        changeLang (lang) {
            this.$vuetify.lang.current = lang
        }
    }
}
</script>

<style lang="scss">
.lang-selector {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    .lang-btn {
        margin-top: -3px;
        margin-left: 8px;
        width: 20px;
        height: 20px;
    }
}
.lang-item {
    display: flex;
    align-items: center;
    font-size: 14px;

    img {
        width: 16px;
        height: 16px;
        margin-top: -5px;
        margin-right: 8px;
    }
}

.th-locale .lang-item {
    font-weight: 300;
}

.v-list-item:hover {
    cursor: pointer;
    background-color: #00000022;
}
</style>
