<template>
    <div class="zoxa-home">
        <ZoxaLogo />

        <!-- <button class="btn-zoxa">{{ $vuetify.lang.t('$vuetify.HomeView.ourPortfolio') }}</button> -->

        <img src="@/assets/go-down.png" alt="go-down">

        <span class="company-name">ZOXA CO., LTD.</span>

        <span class="company-description">{{ $vuetify.lang.t('$vuetify.HomeView.companyDescription') }}</span>

        <v-row id="services">
            <v-col cols="12">
                <img src="@/assets/custom-software.svg" alt="custom-software">

                <ServiceCard :content="contents.customSoftware" />
            </v-col>
            <v-col cols="12">
                <ServiceCard :content="contents.website" />

                <img src="@/assets/website.svg" alt="website">
            </v-col>
            <v-col cols="12">
                <img src="@/assets/website.svg" alt="website">

                <ServiceCard :content="contents.website" />
            </v-col>
            <v-col cols="12">
                <img src="@/assets/data-scraping.svg" alt="data-scraping">

                <ServiceCard :content="contents.dataScraping" />
            </v-col>
        </v-row>

        <div id="contact">
            <span class="contact-title">{{ $vuetify.lang.t('$vuetify.HomeView.contact') }}</span>

            <ContactForm />
        </div>
    </div>
</template>

<script>
import ZoxaLogo from '@/components/ZoxaLogo'
import ServiceCard from '@/components/ServiceCard'
import ContactForm from '@/components/ContactForm'

export default {
    name: 'HomeView',

    components: {
        ZoxaLogo,
        ServiceCard,
        ContactForm
    },

    data () {
        return {
            contents: {
                customSoftware: {
                    label: 'C',
                    title: 'Custom software',
                    body: 'HomeView.customSoftware'
                },
                website: {
                    label: 'W',
                    title: 'Website',
                    body: 'HomeView.website'
                },
                dataScraping: {
                    label: 'D',
                    title: 'Data scraping',
                    body: 'HomeView.dataScraping'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.zoxa-home {
    display: flex;
    flex-flow: column;
    align-items: center;

    .btn-zoxa {
        margin-top: 48px;
    }

    img[alt="go-down"] {
        width: 16px;
        height: 24px;
        margin-top: 64px;
    }

    .company {
        &-name {
            margin-top: 423px;
            font-size: 32px;
            font-family: Comfortaa;
        }

        &-description {
            margin-top: 22px;
            margin-bottom: 10px;
            font-size: 28px;
            text-align: center;
            padding: 0px 24px;
            font-weight: 200;
        }
    }

    #services {
        padding-top: 100px;
        width: 100%;

        .col {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 160px;
        }

        .col:nth-child(3) {
            display: none;
        }

        img[alt="custom-software"] {
            width: 284px;
            height: 305px;
            margin-right: 100px;
        }

        img[alt="website"] {
            width: 318px;
            height: 275px;
            margin-left: 100px;
        }

        img[alt="data-scraping"] {
            width: 351px;
            height: 305px;
            margin-right: 100px;
        }
    }

    #contact {
        width: 100%;
        padding-left: 185px;
        margin: 100px 0px;

        .contact-title {
            font-size: 28px;
            font-weight: bold;
            line-height: 1.14;
            margin-left: 40px;
        }

        .contact-form {
            margin-top: 36px;
            width: 580px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .company {
        &-description {
            font-size: 20px !important;
        }
    }

    #services {
        .col {
            flex-flow: column;
            margin-bottom: 100px !important;
        }

        .col:nth-child(2) {
            display: none !important;
        }

        .col:nth-child(3) {
            display: flex !important;
        }

        img[alt="custom-software"] {
            width: 68% !important;
            margin: unset !important;
        }

        img[alt="website"] {
            width: 77% !important;
            margin: unset !important;
        }

        img[alt="data-scraping"] {
            width: 85% !important;
            margin: unset !important;
        }
    }

    #contact {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding: 5.8vw !important;
        margin: 0px !important;

        .contact-form {
            margin-top: 32px !important;
            width: 88vw !important;
        }
    }
}
</style>
