import { th } from 'vuetify/lib/locale'
import HomeView from './views/HomeView-th'
import NavBar from './components/NavBar-th'
import Contact from './components/Contact-th'

export default {
    ...th,
    NavBar,
    HomeView,
    Contact
}
