<template>
    <div class="contact-form">

        <v-text-field
            dark outlined hide-details
            class="text-input"
            type="text"
            v-model="form.name"
            :placeholder="$vuetify.lang.t('$vuetify.Contact.name')">
        </v-text-field>

        <v-text-field
            dark outlined hide-details
            class="text-input"
            type="text"
            v-model="form.tel"
            :placeholder="$vuetify.lang.t('$vuetify.Contact.tel')">
        </v-text-field>

        <v-select
            class="select-input"
            v-model="form.topic"
            :items="topics"
            :placeholder="$vuetify.lang.t('$vuetify.Contact.topic')"
            dark outlined hide-details>
        </v-select>

        <v-textarea
            dark outlined hide-details
            class="text-input"
            v-model="form.description"
            :placeholder="$vuetify.lang.t('$vuetify.Contact.description')"></v-textarea>

        <div class="zoxa-captcha">
            <vue-recaptcha
                ref="zoxaCaptchaa"
                :sitekey="siteKey"
                :loadRecaptchaScript="true"
                @verify="form.token = arguments[0]"
                @expired="form.token = null"
                @error="form.token = null">
            </vue-recaptcha>
        </div>

        <div class="btn-box">
            <button
                class="btn-zoxa"
                @click="submit"
                :disabled="!canSubmit">
                {{ $vuetify.lang.t('$vuetify.Contact.submit') }}
            </button>
        </div>

        <Modal ref="modal" :height="200"/>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'
import Modal from '@/components/Modal'

export default {
    name: 'ContactForm',

    components: {
        VueRecaptcha,
        Modal
    },

    data () {
        return {
            siteKey: process.env.VUE_APP_SITE_KEY,
            isSending: false,
            form: {
                token: null,
                name: '',
                tel: '',
                topic: null,
                description: ''
            },
            topics: ['ติดต่อสอบถาม', 'ขอใบเสนอราคา', 'แจ้งปัญหา']
        }
    },

    computed: {
        canSubmit () {
            return this.form.name.trim() && this.form.tel.trim() && this.form.topic && this.form.description.trim() && this.form.token && !this.isSending
        }
    },

    methods: {
        reset () {
            this.$refs.zoxaCaptchaa.reset()
            this.form = {
                token: null,
                name: '',
                tel: '',
                topic: '',
                description: ''
            }
        },

        async submit () {
            this.isSending = true
            this.$store.commit('setLoading', true)

            const result = await this.axios.post(`${process.env.VUE_APP_ENDPOINT}/api/contact`, { ...this.form })

            this.reset()
            this.$store.commit('setLoading', false)

            if (result && result.data && result.data.status === 'ok') {
                this.$refs.modal.show(this.$vuetify.lang.t('$vuetify.Contact.mailSentSucceed'))
            } else {
                this.$refs.modal.show(this.$vuetify.lang.t('$vuetify.Contact.mailSentFailed'))
            }

            this.isSending = false
        }
    }
}
</script>

<style lang="scss" scoped>
.contact-form {
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #1a1a1a;

    .zoxa-captcha {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }

    .btn-box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 32px;
    }

    ::v-deep .v-input--is-focused {
        fieldset {
            border: solid 1px #8a6bdd !important;
        }
    }

    .select-input, .text-input {
        margin-bottom: 20px;
        background-color: #1f1f1f;

        &.primary--text {
            color: #8a6bdd !important;
            caret-color: #8a6bdd !important;
        }

        ::v-deep .v-input__slot {
            padding: 15px 20px !important;
            font-family: Quicksand;
            font-size: 14px;
            color: white;
            box-shadow: none !important;

            fieldset {
                border: solid 1px #1a1a1a;
            }

            input, .v-select__selections {
                padding: unset !important;
            }

            .v-input__append-inner, .v-select__selection {
                margin: unset !important;
            }

            .v-icon.primary--text {
                color: #8a6bdd !important;
                caret-color: #8a6bdd !important;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .service-card {
        width: 88% !important;
        padding: 24px !important;

        &-label {
            left: 16px !important;
        }

        &-title {
            // margin-top: 30px;
            margin-left: 8px !important;
        }

        &-body {
            font-size: 16px !important;
            margin-left: 16px !important;
            margin-bottom: 24px !important;
        }
    }
}
</style>
