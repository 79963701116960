export default {
    computed: {
        canSmoothScroll () {
            // eslint-disable-next-line
            const isIE = /*@cc_on!@*/false || !!document.documentMode
            const isSafari = /constructor/i.test(window.HTMLElement) || (
                function (p) {
                    return p.toString() === '[object SafariRemoteNotification]'
                    // eslint-disable-next-line
                })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))
            return !isIE && !isSafari
        }
    },

    methods: {
        scrollTo (id) {
            const el = document.getElementById(id)
            if (this.canSmoothScroll) {
                window.scroll({
                    top: el.getBoundingClientRect().top + window.scrollY,
                    behavior: 'smooth'
                })
            } else {
                el.scrollIntoView()
            }
        }
    }
}
