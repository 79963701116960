<template>
    <div class="zoxa-navbar">
        <NavDrawer class="zoxa-nav-drawer" :items="items" />

        <img
            alt="ZoxaLogo"
            src="@/assets/zoxa-logo.png"
            width="64"
            height="12" />

        <v-spacer></v-spacer>

        <div class="zoxa-navbar-item-list">
            <a  class="zoxa-navbar-item"
                v-for="item in items"
                :key="item.id"
                @click="scrollTo(item.id)">
                {{ item.label }}
            </a>
        </div>

        <LangSelector class="lang" />
    </div>
</template>

<script>
import LangSelector from '@/components/LangSelector'
import NavDrawer from '@/components/NavDrawer'
import navMixin from '@/mixins/navMixin'

export default {
    name: 'NavBar',

    mixins: [navMixin],

    components: {
        LangSelector,
        NavDrawer
    },

    computed: {
        items () {
            return [
                { id: 'services', label: this.$vuetify.lang.t('$vuetify.NavBar.services') },
                // { id: 'portfolio', label: this.$vuetify.lang.t('$vuetify.NavBar.portfolio') },
                // { id: 'clients', label: this.$vuetify.lang.t('$vuetify.NavBar.clients') },
                { id: 'contact', label: this.$vuetify.lang.t('$vuetify.NavBar.contact') }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.zoxa-navbar {
    display: flex;
    align-items: center;
    margin-top: 28px;
    z-index: 5;
    padding: 0px 80px;

    .zoxa-nav-drawer {
        visibility: hidden;
    }

    .zoxa-navbar-item {
        color: white;
        font-size: 14px;
        font-weight: 500;
        margin-right: 35px;
        cursor: pointer;
        text-decoration: none;
    }

    .lang {
        margin-top: 3px;
    }
}

.th-locale .zoxa-navbar-item {
    font-weight: 300;
}

@media only screen and (max-width: 1024px) {
    .zoxa-navbar {
        padding: 0px 20px !important;

        .zoxa-nav-drawer {
            visibility: visible !important;
        }

        .zoxa-navbar-item-list {
            visibility: hidden;
        }
    }
}
</style>
