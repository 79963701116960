<template>
    <v-app class="zoxa" :class="localeScss">
        <NavBar />

        <img src="@/assets/bg1.png" alt="bg-1">

        <img src="@/assets/bg2.png" alt="bg-2">

        <img src="@/assets/bg3.png" alt="bg-3">

        <img src="@/assets/bg4.png" alt="bg-4">

        <v-main>
            <router-view/>
        </v-main>

        <Footer />

        <div class="loader" v-if="$store.state.loading">
            <GridLoader
                ref="loader"
                color="#FFFFFF"
                :loading="$store.state.loading"
                :size="50"
                sizeUnit="px"/>
        </div>
    </v-app>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

export default {
    name: 'App',

    components: {
        NavBar,
        Footer
    },

    computed: {
        localeScss () {
            return this.$vuetify.lang.current + '-locale'
        }
    }
}
</script>

<style lang="scss" scoped>
.zoxa {
    background-color: #1A1A1A !important;
    position: relative;

    img[alt="bg-1"] {
        width: 372px;
        height: 301px;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    img[alt="bg-2"] {
        width: 249px;
        height: 349px;
        object-fit: contain;
        position: absolute;
        top: 108px;
        right: 0;
        z-index: 2;
    }

    img[alt="bg-3"] {
        width: 100vw;
        object-fit: fill;
        object-position: center;
        position: absolute;
        top: 420px;
        max-height: 586px;
    }

    img[alt="bg-4"] {
        width: 100vw;
        object-fit: contain;
        object-position: center;
        position: absolute;
        bottom: 100px;
    }

    footer {
        z-index: 5;
    }

    .loader {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00000099;
        z-index: 10;
    }
}

@media only screen and (max-width: 1024px) {
    img[alt="bg-1"] {
        width: 210px !important;
        height: 170px !important;
    }

    img[alt="bg-2"] {
        width: 230px !important;
        height: 164px !important;
    }

    img[alt="bg-3"] {
        top: 380px !important;
        object-fit: none !important;
        height: 586px;
    }

    img[alt="bg-4"] {
        // top: 3350px !important;
        object-fit: none !important;
        height: 535px;
    }
}

// @media only screen and (min-width: 1366px) {
//     img[alt="bg-3"] {
//         background-size: cover !important;
//     }
// }
</style>
